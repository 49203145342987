<template>
  <div
    v-if="isParentSite"
    class="homepage-subcommunities"
    :style="containerStyles"
  >
    <h2 class="homepage-subcommunities__heading">
      {{ title }}
    </h2>
    <p class="homepage-subcommunities__text">
      {{ description }}
    </p>
    <ul class="homepage-subcommunities__list">
      <li
        v-for="community in sortedChildCommunities"
        :key="`homepage-subcommunities-${community.prefix}`"
        class="homepage-subcommunities__list-item"
      >
        <a
          :href="`https://${community.name}`"
          class="homepage-subcommunities__link link link--unstyled"
          target="_blank"
        >
          {{ formattedCommunityName(community.name) }}
          <icon-component
            class="homepage-subcommunities__icon"
            name="caret"
            title="caret icon"
          />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import childSitesMixin from '@/mixins/child-sites-mixin';

export default {
  name: 'HomepageSubcommunities',
  mixins: [childSitesMixin],
  props: {
    description: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    containerStyles() {
      return {
        'background-image': 'url("/wp-content/themes/hu2/images/community-background.png")',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_homepage-subcommunities';
</style>
